import React from "react";

import styled from "styled-components";

import InputComponent from "./InputComponent";


const InputStyle = styled.input<{ $error?: boolean }>`
    user-select: none;
    width: 300px;
    height: 45px;
    padding: 0 12px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid #E9E9EA;
    background-color: #F6F7F9;
`;

interface InputComponentProps {
    label?: string;
    name?: string;
    placeholder?: string;
    readOnly?: boolean;
    value?: string;
    onChange?: (e: any) => void;
    errorMessage?: string;
    autoComplete?: string;
}

const InputDate: React.FC<InputComponentProps> = ({
    label,
    name,
    readOnly,
    value,
    onChange,
    errorMessage,
}) => {
    return (
        <InputComponent label={label} errorMessage={errorMessage}>
            <InputStyle 
                $error={!!errorMessage}
                readOnly={readOnly}
                type='date'
                name={name}
                value={value}
                onChange={onChange}
            />
        </InputComponent>
    );
}

export default InputDate;