import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import ThreeDotsIcon from "../../assets/ThreeDotsIcon.svg";
import EditIcon from '../../assets/greyEditIcon.svg';
import ShareIcon from '../../assets/greyShareIcon.svg';
import CopyMessage from '../../assets/copy.svg';
import CloudIcon from '../../assets/cloud.svg';
import DeleteIcon from '../../assets/DeleteIcon.svg';

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";


const ActionsStyle = styled.div`
    position: relative;
    height: 24px;
    border-radius: 6px;
`;

const ActionsImgStyle = styled.img`
    width: 24px;
    height: 100%;
    padding: 4px;
    border-radius: 6px;
    &:hover {
        background-color: #d9e8f8ce;
    }
`;

const ActionMenuItemsStyle = styled.div`
    position: absolute;
    top: calc(100% + 1px);
    right: 0;
    z-index: 1;
    border: 1px solid #E9E9EA;
    background-color: #FFF;
    border-radius: 8px;
    width: 160px;
`;

const ActionItemIcon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`;

const ActionItem = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 12px;
    &:hover {
        opacity: .7;
    }
`;

interface DocumentActionMenuProps {
    onEdit: () => void;
    onDownload: () => void;
    onDelete: () => void;
}

const DocumentActionMenu: React.FC<DocumentActionMenuProps> = ({ onEdit, onDownload, onDelete }) => {
    const { t } = useTranslation();
    
    const actionMenuRef = useRef<HTMLDivElement>(null);

    const [isVisible, setIsVisible] = useState(false);
    
    useEffect(() => {
        const handlerClick = (e: MouseEvent) => {
            if (actionMenuRef.current && !actionMenuRef.current.contains(e.target as Node)) {
                setIsVisible(false);
            }
        };

        document.addEventListener('mousedown', handlerClick);
        return () => {
            document.removeEventListener('mousedown', handlerClick);
        };
    }, []);

    return (
        <ActionsStyle ref={actionMenuRef} onClick={(e) => e.stopPropagation()}>
            <ActionsImgStyle 
                src={ThreeDotsIcon}
                alt='icon'
                onClick={() => setIsVisible(!isVisible)}
            />

            {
                isVisible &&
                <ActionMenuItemsStyle>
                    <ActionItem onClick={onEdit} >
                        <ActionItemIcon src={EditIcon} width='16px' />
                        <Text fontWeight="400" fontSize='14px' color="#201F29">Edit</Text>
                    </ActionItem>
                    
                    <ActionItem onClick={() => {}}>
                        <ActionItemIcon src={CopyMessage} width='16px' />
                        <Text fontWeight="400" fontSize='14px' color="#201F29">Copy link</Text>
                    </ActionItem>

                    <ActionItem onClick={() => {}}>
                        <ActionItemIcon src={ShareIcon} width='16px'  />
                        <Text fontWeight="400" fontSize='14px' color="#201F29">{t('my_cases.action_menu.share')}</Text>
                    </ActionItem>

                    <ActionItem 
                        onClick={() => {
                            onDownload();
                            setIsVisible(false);
                        }}
                    >
                        <ActionItemIcon src={CloudIcon} width='16px' />
                        <Text fontWeight="400" fontSize='14px' color="#201F29">Download</Text>
                    </ActionItem>

					<Container $width="100%" $height="1px" $backgroundColor="#E9E9EA" />

                    <ActionItem 
                        onClick={() => {
                            onDelete();
                            setIsVisible(false);
                        }}
                    >
                        <ActionItemIcon src={DeleteIcon} width='16px' />
                        <Text fontWeight="400" fontSize='14px' color="#201F29">Delete</Text>
                    </ActionItem>
                </ActionMenuItemsStyle>
            }
        </ActionsStyle>
    );
}


export default DocumentActionMenu;