import { useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import PrimaryButton from "../../Buttons/PrimaryButton";

import Text from "../../../shared/lib/widgets/Text";
import Container from "../../../shared/lib/widgets/Container";
import RowWidget from "../../../shared/lib/widgets/RowWidget";
import { toast } from "react-toastify";


const AffiliateEmailVerification = () => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = () => {
        toast.info('This part is under development.')
        if (!isLoading) {
            setIsLoading(true);
            navigate('/affiliate');
            setIsLoading(false);
        }
    }
    
    return (
        <RowWidget $width="100%" $height="85vh" $justifyContent="center" $alignItems="center" $margin="32px 0">
            <RowWidget $width="520px" $height="100%" $flexDirection="column" $justifyContent="space-between">
                <Container $margin="64px 0 0 0">
                    <RowWidget $justifyContent="center">
                        <img style={{ height: '32px', marginRight: '8px', }} src="/images/img_group.svg" alt="logo" />
                        <img style={{ height: '32px' }} src="/images/img_group_light_blue_a700.svg" alt="logo" />
                    </RowWidget>

                    <Container $height="80px" />

                    <RowWidget $justifyContent="center">
                        <img src="/images/check_email.svg" alt="key" style={{ width: '80px', height: '80px' }} />
                    </RowWidget>

                    <Container $height="16px" />

                    <Text $textAlign="center" fontWeight="bold">{t('affiliate.email_verification.description')}</Text>

                    <Container $height="16px" />

                    <Text fontWeight="bold" fontSize="28px" $textAlign="center">{t('affiliate.email_verification.title')}</Text>

                    <Container $height="8px" />

                    <Text $textAlign="center">
                        <Trans
                            t={t}
                            i18nKey="affiliate.email_verification.subtitle"
                            values={{ email: 'example@gmail.com' }}
                            components={{ b: <span style={{ fontWeight: 'bold' }} /> }}
                        />
                    </Text>
                    <Text $textAlign="center">
                        <Trans
                            t={t}
                            i18nKey="affiliate.email_verification.subtitle_check"
                            components={{ r: <span style={{ fontWeight: 'bold', cursor: 'pointer', color: '#007FFF' }} onClick={() => toast.info('This part is under development.')} /> }}
                        />
                    </Text>

                    <Container $height="32px" />

                    <RowWidget $justifyContent="center">
                        <PrimaryButton 
                            onClick={handleConfirm}
                            title={isLoading ? 'Loading...' : t('button.verify_email')}
                            $width="60%"
                        />
                    </RowWidget>

                    <Container $height="16px" />
                </Container>
                
                <Text $textAlign="center" color="#7E7D83" fontSize="14px">Copyright © 2024 LegalAssists</Text>
            </RowWidget>
        </RowWidget>
    );
}


export default AffiliateEmailVerification;