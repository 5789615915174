import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Logo from '../../assets/legal_assist_logo.png';
import StartIcon from '../../assets/StarIcon.svg';

import MyCasesIcon from '../../assets/headerIcon/blueFileIcon.svg';
import SubscriptionIcon from '../../assets/headerIcon/Subscriptions.svg';
import AcademyIcon from '../../assets/headerIcon/Academy.svg';
import SettingsIcon from '../../assets/headerIcon/settingIcon.svg';

import UserDropDown from '../DropDown/UserDropDown';

import PrimaryButton from '../Buttons/PrimaryButton';

import RowWidget from '../../shared/lib/widgets/RowWidget';


const HeaderStyle = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    align-items: center;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    gap: 16px;
    height: 70px;
    padding: 0 16px;
    background-color: #FFF;
    border-bottom: 1px solid #2222221e;
    @media (max-width: 1150px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const NavBar = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 32px;
    @media (max-width: 1150px) {
        display: none;
    }
`;

const NavBarItem = styled(Link)<{ $isActive?: boolean }>`
    user-select: none;
    width: fit-content;
    text-decoration: none;
    cursor: pointer;
    color: ${(props) => (props.$isActive ? '#153968' : '#7E7D83')};
    border-bottom: ${(props) => (props.$isActive ? '2px solid #153968' : '2px solid transparent')};
    /* fill: ${(props) => (props.$isActive ? '#153968' : '#7E7D83')}; */
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    white-space: nowrap;
    &:hover {
        color: #153968;
        .icon {
            filter: grayscale(0);
            /* fill: ${(props) => (props.$isActive ? '#153968' : '#7E7D83')}; */
        }
    }
    .icon {
        filter: ${(props) => (props.$isActive ? '' : 'brightness(1.9) saturate(100%) invert(31%) sepia(4%) saturate(0%) hue-rotate(179deg) brightness(93%) contrast(88%)')};
        /* fill: ${(props) => (props.$isActive ? '#153968' : '#7E7D83')}; */
    }
`;

const HideButton = styled.div`
    @media (max-width: 640px) {
        display: none;
    }
`;

const NavBarItemIcon = styled.img`
    width: 24px;
    height: 24px;
`;

const NavBarItemText = styled.div`
    font-size: 16px;
`;

const Header = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();
    const isActive = (path?: string) => location.pathname === path;

    const navBarItems = [
        {
            title: t('header.my_cases'),
            icon: MyCasesIcon,
            path: '/',
        },
        {
            title: t('header.subscriptions'),
            icon: SubscriptionIcon,
            path: '/subscription-plan',
        },
        {
            title: t('header.academy'),
            icon: AcademyIcon,
            path: '/academy',
        },
        {
            title: t('header.settings'),
            icon: SettingsIcon,
            path: '/settings',
        },
    ]
    
    return (
        <HeaderStyle>
            <RowWidget>
                <Link to="/">
                    <img src={Logo} alt="logo" height="30px" style={{ display: 'flex' }} />
                </Link>
            </RowWidget>

            <NavBar>
                {
                    navBarItems.map((item, index) =>
                        <NavBarItem key={index} to={item.path} $isActive={isActive(item.path)}>
                            <NavBarItemIcon className='icon' src={item.icon} alt="icon" />
        
                            <NavBarItemText>{item.title}</NavBarItemText>
                        </NavBarItem>
                    )
                }
            </NavBar>

            <RowWidget $justifyContent='end' $gap='16px'>
                <HideButton>
                    <PrimaryButton 
                        onClick={() => navigate('/subscriptions')}
                        title={t('button.upgrade_plan')}
                        leading={StartIcon}
                        $width='160px'
                    />
                </HideButton>

                <UserDropDown />
            </RowWidget>
        </HeaderStyle >
    );
}


export default Header;
