import { useTranslation } from 'react-i18next';

import LinkCopyIcon from '../../../assets/SharelinkIcon.svg';
import LinkIcon from '../../../assets/link_earn.svg';
import InvitationIcon from '../../../assets/invitation_icon.svg';
import CheckedIcon from '../../../assets/checked_icon.svg';
import AddUserIcon from '../../../assets/add_user_earn.svg';
import CurrencyIcon from '../../../assets/currency_earn.svg';
import WalletIcon from '../../../assets/wallet_earn.svg';

import Input from '../../../components/Inputs/Input';

import TextButton from '../../../components/Buttons/TextButton';
import SecondaryButton from '../../../components/Buttons/SecondaryButton';

import Text from '../../../shared/lib/widgets/Text';
import Container from '../../../shared/lib/widgets/Container';
import RowWidget from '../../../shared/lib/widgets/RowWidget';


const ReferAndEarn = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <RowWidget $gap='12px'>
                <AffiliateInfoCard icon={LinkIcon} title={t('settings.refer_and_earn.info_cards.referrals')} subtitle='0' />
                <AffiliateInfoCard icon={AddUserIcon} title={t('settings.refer_and_earn.info_cards.subscribed')} subtitle='0' />
                <AffiliateInfoCard icon={CurrencyIcon} title={t('settings.refer_and_earn.info_cards.earned')} subtitle='€0' />
                <AffiliateInfoCard icon={WalletIcon} title={t('settings.refer_and_earn.info_cards.paid')} subtitle='€0' />
            </RowWidget>

            <Container $height='16px' />

            <RowWidget $gap='16px'>
                <Container $borderRadius='16px' $border='1px solid #E9E9EA' $width='100%' $padding='20px'>
                    <Text fontWeight='bold' fontSize='20px'>{t('settings.refer_and_earn.referal_card.title')}</Text>

                    <Container $height='6px' />

                    <Text color='#908F94'>{t('settings.refer_and_earn.referal_card.subtitle')}</Text>

                    <Container $height='24px' />

                    <RowWidget $alignItems='center' $margin='0 10%' style={{ userSelect: 'none' }}>
                        <div style={{
                            height: '58px',
                            width: '58px',
                            borderRadius: '50%',
                            backgroundColor: '#FFF',
                            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                            display: 'grid',
                            placeItems: 'center',
                        }}>
                            <img 
                                src={InvitationIcon} 
                                alt="icon" 
                                style={{
                                    height: '24px',
                                    width: '24px',
                                }}
                            />
                        </div>

                        <div style={{ flex: '1', height: '1px', border: '1px dashed #96CBFF' }}></div>

                        <div style={{
                            height: '58px',
                            width: '58px',
                            borderRadius: '50%',
                            backgroundColor: '#FFF',
                            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                            display: 'grid',
                            placeItems: 'center',
                        }}>
                            <img 
                                src={AddUserIcon} 
                                alt="icon" 
                                style={{
                                    height: '24px',
                                    width: '24px',
                                }}
                            />
                        </div>

                        <div style={{ flex: '1', height: '1px', border: '1px dashed #96CBFF' }}></div>

                        <div style={{
                            height: '58px',
                            width: '58px',
                            borderRadius: '50%',
                            backgroundColor: '#FFF',
                            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                            display: 'grid',
                            placeItems: 'center',
                        }}>
                            <img 
                                src={CheckedIcon} 
                                alt="icon" 
                                style={{
                                    height: '24px',
                                    width: '24px',
                                }}
                            />
                        </div>
                    </RowWidget>

                    <Container $height='16px' />

                    <RowWidget $gap='28px'>
                        <Container $width='100%'>
                            <Text $textAlign='center' fontWeight='bold'>{t('settings.refer_and_earn.referal_card.invite.title')}</Text>
                            <Container $height='6px' />
                            <Text $textAlign='center' color='#7E7D83' fontSize='14px'>{t('settings.refer_and_earn.referal_card.invite.subtitle')}</Text>
                        </Container>

                        <Container $width='100%'>
                            <Text $textAlign='center' fontWeight='bold'>{t('settings.refer_and_earn.referal_card.register.title')}</Text>
                            <Container $height='6px' />
                            <Text $textAlign='center' color='#7E7D83' fontSize='14px'>{t('settings.refer_and_earn.referal_card.register.subtitle')}</Text>
                        </Container>

                        <Container $width='100%'>
                            <Text $textAlign='center' fontWeight='bold'>{t('settings.refer_and_earn.referal_card.use_credits.title')}</Text>
                            <Container $height='6px' />
                            <Text $textAlign='center' color='#7E7D83' fontSize='14px'>{t('settings.refer_and_earn.referal_card.use_credits.subtitle')}</Text>
                        </Container>
                    </RowWidget>
                </Container>

                <Container $borderRadius='16px' $border='1px solid #E9E9EA' $width='70%' $padding='20px'>
                    <Text fontSize='18px' fontWeight='bold'>{t('settings.refer_and_earn.share.title')}</Text>

                    <Text color='#908F94' margin='16px 0'>{t('settings.refer_and_earn.share.subtitle')}</Text>

                    <RowWidget $gap='12px' $alignItems='center'>
                        <Input 
                            value={`${process.env.REACT_APP_URL}/&ref=affiliateId`}
                            readOnly={true}
                        />

                        <TextButton 
                            leading={LinkCopyIcon}
                            title={t('button.copy_link')}
                        />
                    </RowWidget>

                    <RowWidget $margin='16px 0' $alignItems='center' $gap='8px'>
                        <Container $backgroundColor='#E9E9EA' $width='100%' $height='1px'/>

                        <Text color='#7E7D83'>{t('settings.refer_and_earn.or')}</Text>

                        <Container $backgroundColor='#E9E9EA' $width='100%' $height='1px'/>
                    </RowWidget>

                    <RowWidget $gap='12px' $justifyContent='center'>
                        <Container $height='50px' $width='50px' $borderRadius='8px' $border='1px solid #E9E9EA' $cursor='pointer' />
                        <Container $height='50px' $width='50px' $borderRadius='8px' $border='1px solid #E9E9EA' $cursor='pointer' />
                        <Container $height='50px' $width='50px' $borderRadius='8px' $border='1px solid #E9E9EA' $cursor='pointer' />
                        <Container $height='50px' $width='50px' $borderRadius='8px' $border='1px solid #E9E9EA' $cursor='pointer' />
                    </RowWidget>
                </Container>
            </RowWidget>

            <Container $height='32px' />

            <Container $backgroundColor='#E6F2FF' $borderRadius='12px' $padding='16px'>
                <RowWidget $justifyContent='space-between' $alignItems='center'>
                    <RowWidget $gap='20px'>
                        <img 
                            src={LinkIcon} 
                            alt="icon" 
                            />
                        
                        <Text color='#153968' fontWeight='bold' fontSize='18px'>{t('settings.refer_and_earn.earn_program')}</Text>
                    </RowWidget>

                    <SecondaryButton 
                        $width='160px'
                        onClick={() => {}}
                        title={t('button.learn_more')}
                    />
                </RowWidget>
            </Container>
        </Container>
    );
}

export default ReferAndEarn;


const AffiliateInfoCard = ({ icon, title, subtitle }: any) => {
    return (
        <Container $borderRadius='16px' $border='1px solid #E9E9EA' $width='100%' $padding='16px 12px' style={{ userSelect: 'none' }}>
            <RowWidget $alignItems='center' $gap='12px'>
                <div style={{
                    height: '48px',
                    width: '48px',
                    borderRadius: '50%',
                    backgroundColor: '#E6F2FF',
                    display: 'grid',
                    placeItems: 'center',
                }}>
                    <img 
                        src={icon} 
                        alt="icon" 
                        style={{
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </div>

                <Container>
                    <Text color='#7E7D83' fontWeight='bold' fontSize='14px'>{title}</Text>
                    <Text fontSize='24px' fontWeight='bold'>{subtitle}</Text>
                </Container>
            </RowWidget>
        </Container>
    );
}