import React, { useState } from 'react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import LinkCopyIcon from '../assets/SharelinkIcon.svg';
import LinkIcon from '../assets/link_earn.svg';
import AddUserIcon from '../assets/add_user_earn.svg';
import CurrencyIcon from '../assets/currency_earn.svg';
import WalletIcon from '../assets/wallet_icon.svg';
import ClockIcon from '../assets/clock_icon.svg';
import PayoutIcon from '../assets/payout_icon.svg';
import CheckIcon from '../assets/RightMarkIcon.svg';
import SupportIcon from '../assets/question_assistance_icon.svg';

import Popup from '../components/Popups/Popup';

import Input from '../components/Inputs/Input';

import TabBarButtons from '../components/Buttons/TabBarButtons';

// import SwitchToggle from '../components/Toggles/SwitchToggle';

import TextButton from '../components/Buttons/TextButton';
import PrimaryButton from '../components/Buttons/PrimaryButton';
import SecondaryButton from '../components/Buttons/SecondaryButton';

import Text from '../shared/lib/widgets/Text';
import Container from '../shared/lib/widgets/Container';
import RowWidget from '../shared/lib/widgets/RowWidget';


const Affiliate: React.FC = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [selectedSegment, setSelectedSegment] = useState<number>(0);
    
    const [selectPayoutMethod, setSelectPayoutMethod] = useState<boolean>(false);
    const [accountDetails, setAccountDetails] = useState<boolean>(false);
    const [paypalDetails, setPaypalDetails] = useState<boolean>(false);
    const [confirmWithdrawal, setConfirmWithdrawal] = useState<boolean>(false);
    const [successWithdrawl, setSuccessWithdrawl] = useState<boolean>(false);

    return (
        <Container $backgroundColor='#F9FAFC' $padding='32px 0' style={{ minHeight: '92vh' }}>
            {
                selectPayoutMethod &&
                    <SelectPayoutMethodPopup onClose={() => setSelectPayoutMethod(false)} onSubmit={() => setAccountDetails(true)} />
            }
            
            {
                accountDetails &&
                    <AccountDetailsPopup onClose={() => setAccountDetails(false)} onBack={() => setSelectPayoutMethod(true)} onSubmit={() => setConfirmWithdrawal(true)} />
            }
            
            {
                paypalDetails &&
                    <PayPalDetailsPopup onClose={() => setPaypalDetails(false)} onBack={() => setSelectPayoutMethod(true)} onSubmit={() => setConfirmWithdrawal(true)} />
            }
            
            {
                confirmWithdrawal &&
                    <ConfirmPayoutWithdrawalPopup onClose={() => setConfirmWithdrawal(false)} onBack={() => setAccountDetails(true)} onSubmit={() => setSuccessWithdrawl(true)} />
            }
            
            {
                successWithdrawl &&
                    <SuccessWithdrawlPopup onClose={() => setSuccessWithdrawl(false)} />
            }

            <RowWidget $justifyContent='center'>
                <Container $backgroundColor='#FFF' $padding='20px 32px' $borderRadius='12px' $width='1140px'>
                    <RowWidget $justifyContent='space-between' $alignItems='start'>
                        <Container>
                            <Text fontSize='20px' color='#201F29' fontWeight='bold'>{t('affiliate.title')}</Text>
                            
                            <Container $height='10px' />
                            
                            <Text fontSize='16px' color='#908F94'>{t('affiliate.subtitle')}</Text>
                        </Container>

                        <TextButton 
                            leading={SupportIcon}
                            title={t('button.support_and_assistance')}
                            color='#7E7D83'
                            onClick={() => navigate('/support')}
                        />
                    </RowWidget>
                    
                    <Container $height='32px' />

                    <TabBarButtons 
                        onClick={setSelectedSegment}
                        value={selectedSegment}
                        items={[t('affiliate.toggle.dashboard'), t('affiliate.toggle.marketing')]}
                    />

                    <Container $height='32px' />

                    <RowWidget $gap='12px'>
                        <AffiliateInfoCard icon={LinkIcon} title={t('affiliate.info_cards.referris')} subtitle='0' />
                        <AffiliateInfoCard icon={AddUserIcon} title={t('affiliate.info_cards.sign_ups')} subtitle='0' />
                        <AffiliateInfoCard icon={CurrencyIcon} title={t('affiliate.info_cards.earning')} subtitle='€0' />
                    </RowWidget>

                    <Container $height='16px' />

                    <RowWidget $gap='16px'>
                        <Container $borderRadius='16px' $border='1px solid #E9E9EA' $width='100%' $padding='20px'>
                            <RowWidget $justifyContent='space-between' $alignItems='center'>
                                <Text fontWeight='bold' fontSize='18px'>{t('affiliate.payout_card.title')}</Text>

                                <RowWidget $gap='12px'>
                                    <Container $borderRadius='40px' $border='1px solid #E9E9EA' $padding='6px'>
                                        <RowWidget $gap='8px' $alignItems='center'>
                                            <img 
                                                src={WalletIcon} 
                                                alt="icon" 
                                            />
                                            <Text color='#7E7D83' fontSize='14px'>{t('affiliate.payout_card.minimum_payout')}</Text>
                                        </RowWidget>
                                    </Container>

                                    <Container $borderRadius='40px' $border='1px solid #E9E9EA' $padding='6px'>
                                        <RowWidget $gap='8px' $alignItems='center'>
                                            <img 
                                                src={ClockIcon} 
                                                alt="icon" 
                                            />
                                            <Text color='#7E7D83' fontSize='14px'>{t('affiliate.payout_card.processing_time')}</Text>
                                        </RowWidget>
                                    </Container>
                                </RowWidget>
                            </RowWidget>

                            <Container $backgroundColor='#E9E9EA' $width='100%' $height='1px' $margin='20px 0' />

                            <Container $backgroundColor='#E6F2FF' $borderRadius='12px' $width='100%' $padding='30px 0'>
                                <Text $textAlign='center' fontSize='38px' fontWeight='bold' color='#007FFF'>$0,00</Text>
                                <Text $textAlign='center' fontWeight='bold'>Balance</Text>
                            </Container>

                            <Container $height='20px' />

                            <PrimaryButton 
                                onClick={() => setSelectPayoutMethod(true)}
                                leading={PayoutIcon}
                                title={t('affiliate.payout_card.title')}
                                $width='100%'
                                // $isDisabled={true}
                            />
                        </Container>

                        <Container $borderRadius='16px' $border='1px solid #E9E9EA' $width='70%' $padding='20px'>
                            <Text fontSize='18px' fontWeight='bold'>{t('affiliate.share.title')}</Text>

                            <Text color='#908F94' margin='16px 0'>{t('affiliate.share.subtitle')}</Text>

                            <RowWidget $gap='12px' $alignItems='center'>
                                <Input 
                                    value={`${process.env.REACT_APP_URL}/&ref=affiliateId`}
                                    readOnly={true}
                                />

                                <TextButton 
                                    leading={LinkCopyIcon}
                                    title={t('button.copy_link')}
                                />
                            </RowWidget>

                            <RowWidget $margin='16px 0' $alignItems='center' $gap='8px'>
                                <Container $backgroundColor='#E9E9EA' $width='100%' $height='1px'/>

                                <Text color='#7E7D83'>{t('affiliate.share.or')}</Text>

                                <Container $backgroundColor='#E9E9EA' $width='100%' $height='1px'/>
                            </RowWidget>

                            <RowWidget $gap='12px' $justifyContent='center'>
                                <Container $height='50px' $width='50px' $borderRadius='8px' $border='1px solid #E9E9EA' $cursor='pointer' />
                                <Container $height='50px' $width='50px' $borderRadius='8px' $border='1px solid #E9E9EA' $cursor='pointer' />
                                <Container $height='50px' $width='50px' $borderRadius='8px' $border='1px solid #E9E9EA' $cursor='pointer' />
                                <Container $height='50px' $width='50px' $borderRadius='8px' $border='1px solid #E9E9EA' $cursor='pointer' />
                            </RowWidget>
                        </Container>
                    </RowWidget>

                    <Container $height='50px' />

                    <Text fontWeight='bold'>{t('affiliate.referrals.title')}</Text>

                    <Text margin='16px 0' color='#908F94'>{t('affiliate.referrals.subtitle')}</Text>

                    <ReferralsTable>
                        <RowWidget $justifyContent='center' $alignItems='center' $height='80px'>
                            <Text fontSize='18px' color='grey'>{t('empty_list.record')}</Text>
                        </RowWidget>
                    </ReferralsTable>
                </Container>
            </RowWidget>
        </Container>
    );
};

export default Affiliate;

const AffiliateInfoCard = ({ icon, title, subtitle }: any) => {
    return (
        <Container $borderRadius='16px' $border='1px solid #E9E9EA' $width='100%' $padding='16px 12px' style={{ userSelect: 'none' }}>
            <RowWidget $alignItems='center' $gap='12px'>
                <div style={{
                    height: '48px',
                    width: '48px',
                    borderRadius: '50%',
                    backgroundColor: '#E6F2FF',
                    display: 'grid',
                    placeItems: 'center',
                }}>
                    <img 
                        src={icon} 
                        alt="icon" 
                        style={{
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </div>

                <Container>
                    <Text color='#7E7D83' fontWeight='bold' fontSize='14px'>{title}</Text>
                    <Text fontSize='24px' fontWeight='bold'>{subtitle}</Text>
                </Container>
            </RowWidget>
        </Container>
    );
}

interface SelectPayoutMethodProps {
    onClose: () => void;
    onSubmit: () => void;
}

const SelectPayoutMethodPopup: React.FC<SelectPayoutMethodProps> = ({ onClose, onSubmit }) => {
    const [amount, setAmount] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value;

        if (!/^[\d,]*$/.test(inputValue)) return;

        if (inputValue.includes(',')) {
            const parts = inputValue.split(',');
            if (parts[1]?.length > 2) {
                inputValue = `${parts[0]},${parts[1].slice(0, 2)}`;
            }
        }

        setAmount(inputValue);
    };
    
    return (
        <Popup title='Select payout method' onClose={onClose} width='600px'>
            <Container>
                <Input 
                    label='Payout amount (EUR)'
                    value={amount.toString()}
                    onChange={handleInputChange}
                    placeholder='0.00'
                />

                <Container $height='6px' />

                <Text color='#A4A3A7' fontSize='14px'>€0,00 available for payout</Text>

                <Container $height='32px' />
                
                <Text color='#46454D' fontSize='14px'>Where you'd like us to send your money:</Text>

                <Container $height='12px' />

                <PayloutToggle />

                <Container $height='32px' />

                {/* <RowWidget $justifyContent='space-between' $alignItems='center' $gap='8px'>
                    <Text fontWeight='bold'>Add new payment method</Text>

                    <SwitchToggle label={false ? 'On' : 'Off'} isActive={false} onToggle={() => {}} />
                </RowWidget>

                <Container $height='32px' />

                {
                    false &&
                        <>
                            <Text color='#46454D' fontSize='14px'>Where you'd like us to send your money:</Text>

                            <Container $height='12px' />

                            <PayloutToggle />

                            <Container $height='32px' />
                        </>
                } */}

                <PrimaryButton 
                    onClick={() => {onClose(); onSubmit();}}
                    title='Continue'
                    $width='180px'
                />
            </Container>
        </Popup>
    );
}

interface AccountDetailsProps {
    onClose: () => void;
    onBack: () => void;
    onSubmit: () => void;
}

const AccountDetailsPopup: React.FC<AccountDetailsProps> = ({ onClose, onBack, onSubmit }) => {
    // const [form, setForm] = useState('');
    
    return (
        <Popup title='Enter your bank account details' onClose={onClose} width='600px'>
            <Container>
                <Input 
                    label='Bank Name'
                    placeholder='Enter the name of your bank'
                    value=''
                    onChange={() => {}}
                />

                <Container $height='20px' />

                <RowWidget $gap='16px'>
                    <Input 
                        label='First Name'
                        placeholder='Enter your first name'
                        value=''
                        onChange={() => {}}
                    />

                    <Input 
                        label='Last Name'
                        placeholder='Enter your last name'
                        value=''
                        onChange={() => {}}
                    />
                </RowWidget>

                <Container $height='20px' />

                <Input 
                    label='Account Number'
                    placeholder='Enter your professional address'
                    value=''
                    onChange={() => {}}
                />

                <Container $height='20px' />

                <RowWidget $gap='16px'>
                    <Input 
                        label='Account Number'
                        placeholder='Ex. 00000000'
                        value=''
                        onChange={() => {}}
                    />

                    <Input 
                        label='Routing/Sort Code'
                        placeholder='Ex. 0000'
                        value=''
                        onChange={() => {}}
                    />
                </RowWidget>
                
                <Container $height='20px' />

                <Input 
                    label='Bank Address (optional for international transfers)'
                    placeholder='Enter bank address'
                    value=''
                    onChange={() => {}}
                />

                <Container $height='20px' />

                <RowWidget $alignItems='center' $gap='8px'>
                    <input 
                        type='checkbox'
                        style={{
                            width: '20px',
                            height: '20px',
                        }}
                    />
                
                    <Text color='#46454D' fontSize='14px'>Save this payout information for future withdrawls</Text>
                </RowWidget>

                <Container $height='32px' />

                <RowWidget $justifyContent='space-between' $gap='16px'>
                    <SecondaryButton 
                        onClick={() => {
                            onClose();
                            onBack();
                        }}
                        title='Back'
                        $width='120px'
                        $borderColor='#DCDCDD'
                    />
                
                    <PrimaryButton 
                        onClick={() => {
                            onClose();
                            onSubmit();
                        }}
                        title='Next'
                        $width='180px'
                    />
                </RowWidget>
            </Container>
        </Popup>
    );
}

interface PayPalDetailsProps {
    onClose: () => void;
    onBack: () => void;
    onSubmit: () => void;
}

const PayPalDetailsPopup: React.FC<PayPalDetailsProps> = ({ onClose, onBack, onSubmit }) => {
    return (
        <Popup title='Enter your PayPal account details' onClose={onClose} width='600px'>
            <Container>

                <RowWidget $gap='16px'>
                    <Input 
                        label='PayPal Email Address'
                        placeholder='Enter your PayPal email address'
                        value=''
                        onChange={() => {}}
                    />

                    <Input 
                        label='Confirm PayPal Email'
                        placeholder='Enter your PayPal email address'
                        value=''
                        onChange={() => {}}
                    />
                </RowWidget>

                <Container $height='20px' />

                <RowWidget $alignItems='center' $gap='8px'>
                    <input 
                        type='checkbox'
                        style={{
                            width: '20px',
                            height: '20px',
                        }}
                    />
                
                    <Text color='#46454D' fontSize='14px'>Save this payout information for future withdrawls</Text>
                </RowWidget>

                <Container $height='32px' />

                <RowWidget $justifyContent='space-between' $gap='16px'>
                    <SecondaryButton 
                        onClick={() => {
                            onClose();
                            onBack();
                        }}
                        title='Back'
                        $width='120px'
                        $borderColor='#DCDCDD'
                    />
                
                    <PrimaryButton 
                        onClick={() => {
                            onClose();
                            onSubmit();
                        }}
                        title='Next'
                        $width='180px'
                    />
                </RowWidget>
            </Container>
        </Popup>
    );
}

interface ConfirmPayoutWithdrawalProps {
    onClose: () => void;
    onBack: () => void;
    onSubmit: () => void;
}

const ConfirmPayoutWithdrawalPopup: React.FC<ConfirmPayoutWithdrawalProps> = ({ onClose, onBack, onSubmit }) => {
    // const [form, setForm] = useState('');
    
    return (
        <Popup title='Confirm your withdrawal' onClose={onClose} width='600px'>
            <Container>
                <RowWidget $gap='8px'>
                    <Text fontWeight='bold'>Method:</Text>
                    <Text>Bank Transfer</Text>
                </RowWidget>

                <Container $height='20px' />

                <RowWidget $gap='8px'>
                    <Text fontWeight='bold'>Amount:</Text>
                    <Text>0,00</Text>
                </RowWidget>

                <Container $height='20px' />

                <Text fontWeight='bold' fontSize='14px'>Payout Information:</Text>

                <Container $height='8px' />

                <Container $backgroundColor='#FFF' $borderRadius='8px' $border='1px solid #E9E9EA' $padding='16px'>
                    <RowWidget $gap='8px'>
                        <Text color='#7E7D83' fontWeight='bold'>Bank Name:</Text>
                        <Text>XYZ</Text>
                    </RowWidget>

                    <Container $height='8px' />

                    <RowWidget $gap='8px'>
                        <Text color='#7E7D83' fontWeight='bold'>Account Number:</Text>
                        <Text>XXXX1234</Text>
                    </RowWidget>
                </Container>

                <Container $height='32px' />

                <RowWidget $justifyContent='space-between' $gap='16px'>
                    <SecondaryButton 
                        onClick={() => {
                            onClose();
                            onBack();
                        }}
                        title='Back'
                        $width='120px'
                        $borderColor='#DCDCDD'
                    />
                
                    <PrimaryButton 
                        onClick={() => {
                            onClose();
                            onSubmit();
                        }}
                        title='Confirm'
                        $width='180px'
                    />
                </RowWidget>
            </Container>
        </Popup>
    );
}

interface SuccessWithdrawlPopupProps {
    onClose: () => void;
}

const SuccessWithdrawlPopup: React.FC<SuccessWithdrawlPopupProps> = ({ onClose }) => {
    return (
        <Popup title='Success!' onClose={onClose} width='600px'>
            <Container>
                <RowWidget $justifyContent='center'>
                    <img 
                        src="images/reset_success.svg" 
                        alt="key" 
                        style={{ 
                            width: '80px', 
                            height: '80px' 
                        }}
                    />
                </RowWidget>

                <Container $height='32px' />

                <Text fontWeight='bold' fontSize='32px' $textAlign='center'>Your withdrawal request has been submitted</Text>

                <Container $height='16px' />

                <Text $textAlign='center' fontSize='14px'>Your withdrawal is being processed. You should receive the founds in 3-5 business days.</Text>

                <Container $height='32px' />

                <RowWidget $justifyContent='center'>
                    <PrimaryButton 
                        onClick={onClose}
                        title='Done'
                        $width='180px'
                    />
                </RowWidget>
            </Container>
        </Popup>
    );
}

const PayloadUl = styled.ul`
    margin: 16px 0 0 16px;
    font-size: 14px;
    color: #7E7D83;
`;

const PayloutToggle = () => {
    const [toggle, setToggle] = useState(true);
    
    return (
        <RowWidget $gap='12px'>
            <Container $cursor='pointer' $borderRadius='16px' $border={`1px solid ${toggle ? '#4158E3' : '#E9E9EA'}`} $padding='16px' $width='100%' onClick={() => setToggle(true)}>
                <RowWidget $justifyContent='space-between' $alignItems='center'>
                    <Text fontWeight='bold'>Bank Transfer</Text>

                    {
                        toggle &&
                            <img src={CheckIcon} alt="icon" width='15px' height='15px' />
                    }
                </RowWidget>

                <PayloadUl>
                    <li>Get paid in 5-7 bussiness days</li>
                    <li>No fees</li>
                </PayloadUl>
                {/* <RowWidget $justifyContent='space-between' $alignItems='end' $margin='16px 0 0 0'>
                    <Container>
                        <Text color='#7E7D83' fontSize='14px'>Name Surname</Text>
                        <Text color='#7E7D83' fontSize='14px'>Account ending in ****1234</Text>
                    </Container>

                    <TextButton 
                        title='Change'
                        fontSize='14px'
                        fontWeight='bold'
                    />
                </RowWidget> */}
            </Container>

            <Container $cursor='pointer' $borderRadius='16px' $border={`1px solid ${!toggle ? '#4158E3' : '#E9E9EA'}`} $padding='16px' $width='100%' onClick={() => setToggle(false)}>
                <RowWidget $justifyContent='space-between' $alignItems='center'>
                    <Text fontWeight='bold'>PayPal</Text>

                    {
                        !toggle &&
                            <img src={CheckIcon} alt="icon" width='15px' height='15px' />
                    }
                </RowWidget>

                <PayloadUl>
                    <li>Get paid in 5-7 bussiness days</li>
                    <li>No fees</li>
                </PayloadUl>
                {/* <RowWidget $justifyContent='space-between' $alignItems='end' $margin='16px 0 0 0'>
                    <Text color='#7E7D83' fontSize='14px'>user@gmail.com</Text>

                    <TextButton 
                        title='Change'
                        fontSize='14px'
                        fontWeight='bold'
                    />
                </RowWidget> */}
            </Container>
        </RowWidget>
    );
}


const ReferralsTableStyle = styled.div`
    background-color: #F6F7F9;
    border-radius: 12px;
    border: 1px solid #E9E9EA;
    min-width: 600px;
    overflow: hidden;
    @media (max-width: 950px) {
        flex: 1;
    }
`;

const ReferralsTableHeaderStyle = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 220px 220px;
    gap: 8px;
    padding: 10px 12px;
    border-bottom: 1px solid #E9E9EA;
`;

const ReferralsTableCellStyle = styled.div`
    color: #7E7D83;
    font-size: 14px;
    font-weight: bold;
`;

const TableContentStyle = styled.div`
    background-color: #FFF;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
`;

interface ReferralsTableProps {
    children: React.ReactNode;
}

const ReferralsTable: React.FC<ReferralsTableProps> = ({ children }) => {
    const { t } = useTranslation();
    
    return (
        <ReferralsTableStyle>
            <ReferralsTableHeaderStyle>
                <ReferralsTableCellStyle>{t('affiliate.referrals.name')}</ReferralsTableCellStyle>
                <ReferralsTableCellStyle>{t('affiliate.referrals.sign_up_date')}</ReferralsTableCellStyle>
                <ReferralsTableCellStyle>{t('affiliate.referrals.status')}</ReferralsTableCellStyle>
            </ReferralsTableHeaderStyle>
            
            <TableContentStyle>
                {children}
            </TableContentStyle>
        </ReferralsTableStyle>
    );
}
