import React from "react";

import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ShareIcon from '../../assets/share.svg';
import CloudIcon from '../../assets/cloud.svg';
import PrintIcon from '../../assets/printer.svg';
import DeleteIcon from '../../assets/delete.svg';

import { formatDate } from "../../shared/utils/date_format";

import PrimaryButton from "../../components/Buttons/PrimaryButton";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";
import { useAppSelector } from "../../shared/components/useAppSelector/useAppSelector";
import { StateSchema } from "../../app/providers/StoreProvider/config/StateSchema";


const DocumentHeader = styled.div`
    position: sticky;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: #FFF;
    display: grid;
    grid-template-columns: 1fr 700px 1fr;
    align-items: center;
    gap: 16px;
    height: 80px;
    width: 100%;
    border-bottom: 1px solid #E8EBF0;
    padding: 16px;
    @media (max-width: 1080px) {
        display: flex;
        justify-content: space-between;
        position: fixed;
        top: 0;
        height: 70px;
        z-index: 4;
    }
`;

const DocumentHeaderTitle = styled.div`
    white-space: nowrap;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (max-width: 1075px) {
        width: 100%;
    }
    @media (max-width: 500px) {
        display: none;
    }
`;

const DocumentHeaderBack = styled.div`
    padding: 8px;
    height: 100%;
    background-color: #E8EBF0;
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const DocumentExportStyle = styled.img`
    width: 20px;
    height: 20px;
    cursor: pointer;
    @media (max-width: 1340px) {
        display: none;
    }
    @media (max-width: 1080px) {
        display: block;
    }
    @media (max-width: 610px) {
        display: none;
    }
`;

interface EditorHeaderProps {
    file: any;
}

const EditorHeader: React.FC<EditorHeaderProps> = ({ file }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const user = useAppSelector((state: StateSchema) => state.user.userData);

    return (
        <DocumentHeader>
            <RowWidget 
                $alignItems="center"
                $gap="12px"
                $height="100%" 
                style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}
            >
                <DocumentHeaderBack onClick={() => navigate(-1)}>{'<'}</DocumentHeaderBack>

                <DocumentHeaderTitle>
                    <Text fontSize="20px" fontWeight="bold" overflow='hidden' $textOverflow='ellipsis'>{file?.name}</Text>

                    <RowWidget $gap="8px">
                        <Text color="#8E9092" fontSize="14px" overflow='hidden' $textOverflow='ellipsis'>Created {formatDate(file?.lastModified)}</Text>

                        <Container $width="1px" $height="100%" $backgroundColor="#8E9092" />

                        <Text color="#8E9092" fontSize="14px" overflow='hidden' $textOverflow='ellipsis'>{user?.first_name} {user?.last_name}</Text>
                    </RowWidget>
                </DocumentHeaderTitle>
            </RowWidget>

            <Container />

            <RowWidget $justifyContent="flex-end" $alignItems="center" $gap="12px">
                <PrimaryButton 
                    onClick={() => toast.info(t('messages.info.service_not_available'))}
                    title='Save'
                    $width="120px"
                />
                <DocumentExportStyle 
                    onClick={() => toast.info(t('messages.info.service_not_available'))}
                    src={ShareIcon}
                    alt="icon"
                />
                <DocumentExportStyle 
                    onClick={() => toast.info(t('messages.info.service_not_available'))}
                    src={CloudIcon}
                    alt="icon"
                />
                <DocumentExportStyle 
                    onClick={() => toast.info(t('messages.info.service_not_available'))}
                    src={PrintIcon}
                    alt="icon"
                />
                <DocumentExportStyle 
                    onClick={() => toast.info(t('messages.info.service_not_available'))}
                    src={DeleteIcon}
                    alt="icon"
                />
            </RowWidget>
        </DocumentHeader>
    );
}


export default EditorHeader;