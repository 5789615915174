import { useState } from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CloudUploadIcon from '../../assets/cloud_upload.svg'
import DownArrowIcon from '../../assets/down_arrow.svg'
import DocumentIcon from '../../assets/document_icon.svg'
import AIDocumentIcon from '../../assets/ai_document_icon.svg'

import { StateSchema } from "../../app/providers/StoreProvider/config/StateSchema";

import { formatTimestampToDate } from "../../shared/utils/date_format";
import { useAppSelector } from "../../shared/components/useAppSelector/useAppSelector";

import DocumentActionMenu from "../DropDown/DocumentActionsDropdown";

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";


const BrowseButton = styled.label`
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    color: #4158E3;
    font-weight: bold;
    font-size: 16px;
    outline: none;
    border: none;
    background-color: transparent;
    &:hover {
        color: #2E3E9F;
    }
    &:active {
        color: #28368A;
    }
    &:focus {
        color: #28368A;
    }
`;

const DocumentsTitleStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
    user-select: none;
`;

const DocumentsContainerStyle = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 12px;
    padding: 12px;
    cursor: pointer;
    border-radius: 8px;
    background-color: transparent;
    &:hover {
        background-color: #E6F2FF;
    }
`;

const DocumentsDocHeaderStyle = styled.div`
    display: flex;
    gap: 12px;
    overflow: hidden;
`;

const DocumentsDocTitleStyle = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

interface DocumentsListProps {
    title: string;
    items: any;
    isOpen?: boolean;
}

const DocumentsList: React.FC<DocumentsListProps> = ({ title, items, isOpen = false }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const user = useAppSelector((state: StateSchema) => state.user.userData);

    const [isVisible, setIsVisible] = useState(isOpen);

    const [files, setFiles] = useState<File[]>([]);

    const handleFileChange = (e: any) => {
        e.preventDefault();
        // toast.info(t('messages.info.service_not_available'));

        const selectedFiles = e.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            const newFiles = Array.from(selectedFiles);
            setFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
        }
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        // toast.info(t('messages.info.service_not_available'));

        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length > 0) {
            const newFiles = Array.from(droppedFiles);
            setFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
        }
    };

    const allFiles = [...items, ...files];

    const handleDownload = (file: any) => {
        if (file) {
            const link = document.createElement('a');
            link.href = file.url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('No file data available for download');
        }
    };
    
    return (
        <>
            <DocumentsTitleStyle onClick={() => allFiles.length > 0 && setIsVisible(!isVisible)}>
                <Text color='#7E7D83' fontWeight='bold'>{title}</Text>

                {
                    allFiles.length > 0 &&
                        <img 
                            src={DownArrowIcon} 
                            alt='icon' 
                            style={{ 
                                rotate: isVisible ? '0deg' : '180deg' 
                            }}
                        />
                }
            </DocumentsTitleStyle>

            {
                isVisible &&
                <Container $margin='8px'>
                    {
                        title === t('chat.sidebar.documents.uploaded_by_lawyer') &&
                            <Container
                                $border="1px dashed #96CBFF"
                                $padding="20px"
                                $borderRadius="12px"
                                $margin="12px"
                                onDrop={handleDrop}
                                onDragOver={(event) => event.preventDefault()}
                            >
                                <img
                                    src={CloudUploadIcon}
                                    alt="icon"
                                    style={{
                                        margin: '0 auto',
                                        display: 'flex',
                                        width: '40px',
                                    }}
                                />

                                <Container $height="16px" />

                                <Text $textAlign="center" color="#9FAEC1">{t('empty_list.documents')}</Text>

                                <input
                                    type="file"
                                    hidden
                                    id="browse"
                                    onChange={handleFileChange}
                                    accept=".docx,.pptx,.txt,.xlsx"
                                    multiple
                                />

                                <RowWidget $justifyContent="center">
                                    <BrowseButton htmlFor="browse">{t('button.browse')}</BrowseButton>
                                </RowWidget>
                            </Container>
                    }

                    {
                        allFiles.map((item: any, index: number) =>
                            <DocumentsContainerStyle key={index}>
                                <DocumentsDocHeaderStyle>
                                    <img
                                        src={item.creator === 'AI' ? AIDocumentIcon : DocumentIcon}
                                        alt="icon"
                                        width={item.creator === 'AI' ? '22px' : '20px'}
                                        height={item.creator === 'AI' ? '22px' : '24px'}
                                    />

                                    <DocumentsDocTitleStyle>
                                        <Text margin='0 0 4px 0' fontWeight='bold' overflow='hidden' $textOverflow='ellipsis'>{item.name}</Text>

                                        <Text fontSize='14px' color='#A4A3A7'>{item.creator === 'AI' ? 'AI' : `${user?.first_name} ${user?.last_name}`} | {formatTimestampToDate(item.lastModified)}</Text>
                                    </DocumentsDocTitleStyle>
                                </DocumentsDocHeaderStyle>

                                <DocumentActionMenu 
                                    onEdit={() => navigate('/document-edit', { state: { file: item } })} 
                                    onDownload={() => handleDownload(item)} 
                                    onDelete={() => setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))} 
                                />
                            </DocumentsContainerStyle>
                        )
                    }
                </Container>
            }
        </>
    );
}


export default DocumentsList;
