import React, { useState } from "react";

import { toast } from "react-toastify";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import OrangeCancelIcon from '../../assets/OrangeCancelIcon.svg';

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";

import DropDown from "../DropDown/DropDown";

import Popup from "./Popup"

import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";

import { StateSchema } from "../../app/providers/StoreProvider/config/StateSchema";
import { useAppSelector } from "../../shared/components/useAppSelector/useAppSelector";
import { cancelSubscriptionCase, subscriptionPlans } from "../../shared/consts/default_values";
import Container from "../../shared/lib/widgets/Container";


const Icon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 8px;
`;

interface CancelSubscriptionPopupProps {
    onSubmit: () => void;
    onClose: () => void;
}

const CancelSubscriptionPopup: React.FC<CancelSubscriptionPopupProps> = ({ onSubmit, onClose }) => {
    const { t } = useTranslation();
    
    const [cancelPlanReason, setCancelPlanReason] = useState("");
    
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (cancelPlanReason === '')
            return toast.info(t('messages.info.select_subscription_type'));

        onSubmit();
    };
    
    const user = useAppSelector((state: StateSchema) => state.user.userData);

    const subscriptions = subscriptionPlans(user?.subscription_id?.id);

    const userPlan = subscriptions.find(subscription => subscription.id === user?.subscription_id?.id)

    return (
        <Popup title={t('subscriptions.popup.cancel_plan.title')} onClose={onClose} width="650px">
            <RowWidget $flexDirection="column">
                <Text margin="0 0 4px 0" fontSize="24px" fontWeight="600">{t('subscriptions.popup.cancel_plan.subtitle')}</Text>
                <Text margin="0 0 16px 0" fontSize="16px" fontWeight="400" color="rgba(70, 69, 77, 1)">{t('subscriptions.popup.cancel_plan.description')}</Text>
                
                {
                    userPlan &&
                    (t(userPlan!.items, { returnObjects: true }) as string[])
                    .map((item, index) => 
                        <RowWidget key={index} $margin="6px 0" $alignItems="center">
                            <Icon src={OrangeCancelIcon} alt='Cross Icon' width='18px' height='18px' />
                            <Text fontWeight='400' fontSize='14px' >{item}</Text>
                        </RowWidget>
                    )
                }

                <Container $height="16px" />

                <DropDown 
                    label={t('dropdown.calcel_plan.label')}
                    dropDownName={t('dropdown.cancel_plan.title')}
                    value={cancelPlanReason} 
                    onChange={setCancelPlanReason} 
                    menuItems={cancelSubscriptionCase} 
                    backgroundColor="#F6F7F9"
                />
                
                <RowWidget $justifyContent="space-between"  $margin="32px 0 0 0" $gap="16px">
                    <SecondaryButton 
                        onClick={onClose}
                        title={t('button.stay_subscribed')}
                        $borderColor='#E9E9EA'
                        $width="120px"
                    />
                    <PrimaryButton 
                        type="red"
                        onClick={cancelPlanReason !== t('messages.info.select_subscription_type') ? handleSubmit : () => toast.info(t('messages.info.select_reason'))}
                        title={t('button.cancel_subscription')}
                        $width='180px'
                    />
                </RowWidget>
            </RowWidget>
        </Popup>
    );
};

export default CancelSubscriptionPopup;