import React from "react";

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";


interface InputComponentProps {
    label?: string;
    children: React.ReactNode;
    errorMessage?: string;
}

const InputComponent: React.FC<InputComponentProps> = (props) => {    
    return (
        <Container $width="100%">
            {
                props.label &&
                <Text 
                    margin="0 0 6px 0"
                    fontSize="14px" 
                    fontWeight="400" 
                    color='rgba(70, 69, 77, 1)'
                    $textAlign="left"
                    style={{
                        userSelect: 'none'
                    }}
                >{props.label}</Text>
            }

            {props.children}

            {
                props.errorMessage &&
                    <Text margin="6px 0 0 0" color="#FF5252" fontSize="14px">{props.errorMessage}</Text>
            }
        </Container>
    );
}

export default InputComponent;