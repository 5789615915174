import React, { useState } from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import QuotesIcon from '../../assets/quotes.svg'

import CitationsDocumentsToggle from "../Toggles/CitationsDocumentsToggle";

import DocumentsList from "../Lists/Documents";

import DocumentPopup from "../Popups/DocumentPopup";

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";


const ChatSidebarStyle = styled.div<{ $sidebarExpanded: boolean }>`
    background-color: #FFF;
    border-right: 1px solid #96CBFF;
    width: ${(props) => props.$sidebarExpanded ? '300px' : '15px'};
    min-width: ${(props) => props.$sidebarExpanded ? '300px' : '15px'};
    position: sticky;
    top: 150px;
    left: 0;
    bottom: 0;
    z-index: 1;
    height: calc(100vh - 150px);
`;

const ChatSidebarToggle = styled.div`
    position: absolute;
    top: 10px;
    right: -14px;
    background-color: #FFF;
    border-radius: 50%;
    border: 1px solid #E6F2FF;
    color: #007FFF;
    height: 28px;
    width: 28px;
    display: grid;
    place-items: center;
    cursor: pointer;
`;

const ChatSidebarDocuments = styled.div`
    overflow-y: auto;
    height: calc(100% - 70px);
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: transparent; 
    }
    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: 40px;
        background: #E8ECF0; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #aaa; 
    }
`;

const DocumentCard = styled.div`
    cursor: pointer;
    border: 1px solid #F2F4F6;
    background-color: #FFF;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 8px;
    display: flex;
    gap: 8px;
    font-size: 14px;
    .document_card_hover {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    &:hover {
        border-color: #E6F2FF;
        background-color: #E6F2FF;
        .document_card_hover {
            color: #007FFF;
        }
    }
`;

interface ChatSidebarProps {
    sidebarExpanded: boolean;
    setSidebarExpanded: (val: boolean) => void;
}

const ChatSidebar: React.FC<ChatSidebarProps> = ({ sidebarExpanded, setSidebarExpanded }) => {
    const { t } = useTranslation();
    
    const [toggle, setToggle] = useState(false)
    const [showDocument, setShowDocument] = useState(false)

    const citationsAndSources: any = [];
    const lawyerDocuments: any = [];
    const aiDocuments: any = [];
    
    return (
        <ChatSidebarStyle $sidebarExpanded={sidebarExpanded}>
            <ChatSidebarToggle onClick={() => setSidebarExpanded(!sidebarExpanded)}>{sidebarExpanded ? '<' : '>'}</ChatSidebarToggle>

            {
                sidebarExpanded &&
                    <>
                        <CitationsDocumentsToggle toggle={toggle} setToggle={setToggle} />

                        {
                            toggle ?
                                <ChatSidebarDocuments>
                                    <DocumentsList 
                                        title={t('chat.sidebar.documents.uploaded_by_lawyer')}
                                        items={lawyerDocuments}
                                        isOpen={true}
                                    />

                                    <Container $height='20px' />

                                    <DocumentsList 
                                        title={t('chat.sidebar.documents.uploaded_by_ai')}
                                        items={aiDocuments}
                                    />
                                </ChatSidebarDocuments>
                                :
                                <ChatSidebarDocuments>
                                    <Container $padding='0 10px 0 20px'>
                                        {
                                            citationsAndSources.length === 0 ?
                                                <Container $padding="32px" $width="100%">
                                                    <img 
                                                        src={QuotesIcon} 
                                                        alt="icon" 
                                                        style={{
                                                            margin: '0 auto',
                                                            display: 'flex',
                                                            width: '40px',
                                                        }}
                                                    />

                                                    <Container $height="16px" />
                                                    
                                                    <Text color="#9FAEC1" $textAlign="center">{t('empty_list.citations_and_sources')}</Text>
                                                </Container>
                                                : 
                                                citationsAndSources.map((item: any, index: number) => 
                                                    <div key={index}>
                                                        {
                                                            showDocument &&
                                                                <DocumentPopup onClose={() => setShowDocument(false)} caseName={'Document name'} index={index + 1} />
                                                        }
                                                        <DocumentCard onClick={() => setShowDocument(true)}>
                                                            <Text fontWeight='bold'color='#007FFF'>[{index + 1}]</Text>
                    
                                                            <Text className='document_card_hover'>{item}</Text>
                                                        </DocumentCard>
                                                    </div>
                                                )
                                        }
                                    </Container>
                                </ChatSidebarDocuments>
                        }
                    </>
            }
        </ChatSidebarStyle>
    );
}


export default ChatSidebar;