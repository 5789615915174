import React, { useState } from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import InfoIcon from '../../assets/info_icon.svg';
import DocumentIcon from '../../assets/document_icon.svg';
import DeleteIcon from '../../assets/delete.svg';
import AttachDocumentsIcon from '../../assets/attach_documents.svg';

import Input from "../Inputs/Input";
import InputPhone from "../Inputs/InputPhone";
import InputTextArea from "../Inputs/InputTextArea";

import PrimaryButton from "../Buttons/PrimaryButton";

import DropDown from "../DropDown/DropDown";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";
import InputDate from "../Inputs/InputDate";


const ChatSettingsSidebarStyle = styled.div`
    background-color: #FFF;
    border-right: 1px solid #E4E4E4;
    width: 300px;
    min-width: 300px;
    padding: 12px;
    position: sticky;
    top: 150px;
    left: 0;
    bottom: 0;
    z-index: 1;
    height: calc(100vh - 150px);
`;

const ChatSettingsSidebarItem = styled.div<{ $active?: boolean }>`
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background-color: ${(props) => props.$active ? '#E8EBF0' : '#fff'};
    padding: 12px;
    cursor: pointer;
    &:hover {
        background-color: #eee;
    }
`;

interface ChatSettingsSidebarProps {
    onClose: () => void;
}

const ChatSettingsSidebar: React.FC<ChatSettingsSidebarProps> = ({ onClose }) => {
    const { t } = useTranslation();
    
    const [selectedSegment, setSelectedSegment] = useState<number>(0);

    const renderSelectedSection = () => {
        switch (selectedSegment) {
            case 0:
                return <CaseDetails />;
            default:
                return <CaseDetails />;
        }
    };

    return (
        <RowWidget $width="100%">
            <ChatSettingsSidebarStyle>
                <ChatSettingsSidebarItem onClick={onClose}>
                    <Text color="#153968" fontWeight="bold">{"<"}</Text>

                    <Text color="#153968" fontWeight="bold">Chat</Text>
                </ChatSettingsSidebarItem>

                <div style={{ borderBottom: '1px solid #E4E4E4', margin: '6px 0' }} />

                <ChatSettingsSidebarItem onClick={() => setSelectedSegment(0)} $active={selectedSegment === 0}>
                    <img src={InfoIcon} alt="icon" />
    
                    <Text color="#153968" fontWeight="bold">{t('chat.case_details.title')}</Text>
                </ChatSettingsSidebarItem>
            </ChatSettingsSidebarStyle>

            {renderSelectedSection()}
        </RowWidget>
    );
}


export default ChatSettingsSidebar;



const CaseDetails = () => {
    const { t } = useTranslation();
    
    return (
        <Container $backgroundColor="#F9FAFC" $padding="30px" $width="100%">
            <Text fontWeight='bold' fontSize='20px'>{t('chat.case_details.title')}</Text>

            <Container $height='10px' />

            <Text color='#908F94'>{t('chat.case_details.subtitle')}</Text>

            <Container $height='32px' />

            <CaseDetailsType />
            <CaseDetailsIdentification />
            <CaseDetailsActivity />
            <CaseDetailsDescription />
            <CaseDetailsRegulatory />

            <RowWidget $justifyContent="flex-end">
                <PrimaryButton 
                    $width="220px"
                    onClick={() => {}}
                    title={t('button.save')}
                    $isDisabled
                />
            </RowWidget>
        </Container>
    );
}

const CaseDetailsType = () => {
    const { t } = useTranslation();

    const [form, setForm] = useState({
        case_type: '',
    });

    const handleInputChange = (e: any) => {
        setForm({
            ...form,
            case_type: e.target.value,
        });
    };
    
    return (
        <Container $backgroundColor="#FFF" $padding="30px" $borderRadius="12px" $margin="0 0 32px 0">
            <RowWidget $gap="15%">
                <Container $width="30%" $height="fit-content">
                    <Text fontSize='18px' fontWeight='bold'>{t('chat.case_details.case_type.title')}</Text>

                    <Container $height="12px" />

                    <Text color='#908F94'>{t('chat.case_details.case_type.subtitle')}</Text>
                </Container>

                <Container>
                    <Text fontWeight='bold' color="#7E7D83" fontSize="14px">{t('chat.case_details.case_type.select_type')}</Text>

                    <Container $height="16px" />

                    <RowWidget $gap="6px" $alignItems="center">
                        <input 
                            type="radio" 
                            name="case_type" 
                            value='judical'
                            onChange={handleInputChange}
                            style={{
                                width: '20px',
                                height: '20px',
                                accentColor: '#4158E3',
                            }}
                            checked={form.case_type === 'judical'}
                        />
                        <Text fontSize="14px">{t('toggle.case_type.judical')}</Text>
                    </RowWidget>

                    <Container $height="12px" />

                    <RowWidget $gap="6px" $alignItems="center">
                        <input 
                            type="radio" 
                            name="case_type" 
                            value='extrajudical'
                            onChange={handleInputChange}
                            style={{
                                width: '20px',
                                height: '20px',
                                accentColor: '#4158E3',
                            }}
                            checked={form.case_type === 'extrajudical'}
                        />
                        <Text fontSize="14px">{t('toggle.case_type.extrajudical')}</Text>
                    </RowWidget>
                </Container>
            </RowWidget>
        </Container>
    );
}

const CaseDetailsIdentification = () => {
    const { t } = useTranslation();

    const [form, setForm] = useState({
        type_of_legal_entity: '',
        type_of_party: '',
        vat_number: '',
        tax_code: '',
        registered_office: '',
        bussiness_registered_number: '',
        lei_code: '',
        share_capital: '',
        name_of_the_legal_representrative: '',
        tax_code_of_legal_representative: '',
        email: '',
        attorney_user_name: '',
        attorney_user_surname: '',
        attorney_bar_association: '',
        attorney_bar_registration_number: '',
        attorney_tax_code: '',
        attorney_law_firm_name: '',
        attorney_email: '',
    });
    
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value,
        });
    };
    
    return (
        <CaseDetailsContainer title={t('chat.case_details.parties_identification.title')} subtitle={t('chat.case_details.parties_identification.subtitle')}>
            <Container $width="100%">
                <Text fontWeight='bold' color="#7E7D83" fontSize="14px">{t('chat.case_details.parties_identification.select_party')}</Text>

                <Container $height="16px" />

                <RowWidget $gap="6px" $alignItems="center">
                    <input 
                        type="radio" 
                        name="case_party" 
                        value='plaintiff'
                        onChange={handleInputChange}
                        style={{
                            width: '20px',
                            height: '20px',
                            accentColor: '#4158E3',
                        }}
                    />
                    <Text fontSize="14px">{t('toggle.identification_party.plaintiff')}</Text>
                </RowWidget>

                <Container $height="12px" />

                <RowWidget $gap="6px" $alignItems="center">
                    <input 
                        type="radio" 
                        name="case_party" 
                        value='defendant'
                        onChange={handleInputChange}
                        style={{
                            width: '20px',
                            height: '20px',
                            accentColor: '#4158E3',
                        }}
                    />
                    <Text fontSize="14px">{t('toggle.identification_party.defendant')}</Text>
                </RowWidget>
                
                <Container $height="20px" />

                <DropDown 
                    label="Type of Party"
                    value={form.type_of_party}
                    onChange={(e) => handleInputChange({ target: { name: 'type_of_party', value: e } })}
                    menuItems={[
                        'Natural Person', 
                        'Legal Entity'
                    ]}
                    backgroundColor="#F6F7F9"
                />

                {
                    form.type_of_party &&
                    <>
                        <Container $height="20px" />

                        <Text fontWeight="bold">{t('chat.case_details.parties_identification.legal_entity_information')}</Text>

                        <Container $height="12px" />

                        <DropDown 
                            label="Type of Legal Entity"
                            value={form.type_of_legal_entity}
                            onChange={(e) => handleInputChange({ target: { name: 'type_of_legal_entity', value: e } })}
                            menuItems={[
                                'Join Stock Company (S.p.A.)', 
                                'Limited Liability Company (S.r.l.)',
                                'Limited Partnership (S.a.s.)',
                                'General Partnership (S.n.c.)',
                                'Cooperative',
                                'Association or Foundation',
                                'Public Entity',
                                'Other (Non-Standard Legal Form)',
                            ]}
                            backgroundColor="#F6F7F9"
                        />

                        {
                            form.type_of_legal_entity &&
                            <>
                                <Container $height="12px" />

                                <RowWidget $gap="16px">
                                    <Input 
                                        label={t('input.vat_number_label')}
                                        placeholder={t('input.vat_number_placeholder')}
                                        name="vat_number"
                                        value={form.vat_number}
                                        onChange={handleInputChange}
                                    />

                                    <Input 
                                        label={t('input.tax_code_label')}
                                        placeholder={t('input.tax_code_placeholder')}
                                        name="tax_code"
                                        value={form.tax_code}
                                        onChange={handleInputChange}
                                    />
                                </RowWidget>

                                <Container $height="12px" />
                                
                                <Input 
                                    label={t('input.registered_office_label')}
                                    placeholder={t('input.registered_office_placeholder')}
                                    name="registered_office"
                                    value={form.registered_office}
                                    onChange={handleInputChange}
                                    />

                                <Container $height="12px" />

                                <RowWidget $gap="16px">
                                    <Input 
                                        label={t('input.bussiness_registered_number_label')}
                                        placeholder={t('input.bussiness_registered_number_placeholder')}
                                        name="bussiness_registered_number"
                                        value={form.bussiness_registered_number}
                                        onChange={handleInputChange}
                                    />

                                    <Input 
                                        label={t('input.lei_code_label')}
                                        placeholder={t('input.lei_code_placeholder')}
                                        name="lei_code"
                                        value={form.lei_code}
                                        onChange={handleInputChange}
                                    />
                                </RowWidget>

                                <Container $height="12px" />

                                <Input 
                                    label={t('input.share_capital_label')}
                                    placeholder={t('input.share_capital_placeholder')}
                                    name="share_capital"
                                    value={form.share_capital}
                                    onChange={handleInputChange}
                                />

                                <Container $height="12px" />

                                <RowWidget $gap="16px">
                                    <Input 
                                        label={t('input.name_of_the_legal_representrative_label')}
                                        placeholder={t('input.name_of_the_legal_representrative_placeholder')}
                                        name="name_of_the_legal_representrative"
                                        value={form.name_of_the_legal_representrative}
                                        onChange={handleInputChange}
                                    />

                                    <Input 
                                        label={t('input.tax_code_of_legal_representative_label')}
                                        placeholder={t('input.tax_code_of_legal_representative_placeholder')}
                                        name="tax_code_of_legal_representative"
                                        value={form.tax_code_of_legal_representative}
                                        onChange={handleInputChange}
                                    />
                                </RowWidget>

                                <Container $height="12px" />

                                <RowWidget $gap="16px">
                                    <Input 
                                        label={t('input.email_label')}
                                        placeholder={t('input.email_placeholder')}
                                        name="email"
                                        value={form.email}
                                        onChange={handleInputChange}
                                    />

                                    <InputPhone 
                                        value=""
                                        onChange={() => {}}
                                    />
                                </RowWidget>

                                <Container $height="20px" />

                                <Text fontWeight="bold">{t('chat.case_details.parties_identification.attorney_information')}</Text>

                                <Container $height="12px" />

                                <RowWidget $gap="16px">
                                    <Input 
                                        label={t('input.user_name_label')}
                                        placeholder={t('input.user_name_placeholder')}
                                        name="attorney_user_name"
                                        value={form.attorney_user_name}
                                        onChange={handleInputChange}
                                    />

                                    <Input 
                                        label={t('input.user_surname_label')}
                                        placeholder={t('input.user_surname_placeholder')}
                                        name="attorney_user_surname"
                                        value={form.attorney_user_surname}
                                        onChange={handleInputChange}
                                    />
                                </RowWidget>

                                <Container $height="12px" />
                                
                                <Input 
                                    label={t('input.bar_association_label')}
                                    placeholder={t('input.bar_association_placeholder')}
                                        name="attorney_bar_association"
                                    value={form.attorney_bar_association}
                                    onChange={handleInputChange}
                                />

                                <Container $height="12px" />

                                <RowWidget $gap="16px">
                                    <Input 
                                        label={t('input.bar_registration_number_label')}
                                        placeholder={t('input.bar_registration_number_placeholder')}
                                        name="attorney_bar_registration_number"
                                        value={form.attorney_bar_registration_number}
                                        onChange={handleInputChange}
                                    />

                                    <Input 
                                        label={t('input.tax_code_label')}
                                        placeholder={t('input.tax_code_placeholder')}
                                        name="attorney_tax_code"
                                        value={form.attorney_tax_code}
                                        onChange={handleInputChange}
                                    />
                                </RowWidget>

                                <Container $height="12px" />

                                <Input 
                                    label={t('input.law_firm_name_label')}
                                    placeholder={t('input.law_firm_name_placeholder')}
                                    name="attorney_law_firm_name"
                                    value={form.attorney_law_firm_name}
                                    onChange={handleInputChange}
                                />

                                <Container $height="12px" />

                                <RowWidget $gap="16px">
                                    <Input 
                                        label={t('input.email_label')}
                                        placeholder={t('input.email_placeholder')}
                                        name="attorney_email"
                                        value={form.attorney_email}
                                        onChange={handleInputChange}
                                    />

                                    <InputPhone 
                                        value=""
                                        onChange={() => {}}
                                    />
                                </RowWidget>
                            </>
                        }
                    </>
                }
            </Container>
        </CaseDetailsContainer>
    );
}

const CaseDetailsActivity = () => {
    const { t } = useTranslation();

    const [form, setForm] = useState({
        type_of_judical_case: '',
        case_type: '',
        court_name: '',
        case_number: '',
        case_subject: '',
        hearing_date: '',
        user_name: '',
        user_surname: '',
        bar_association: '',
        tax_code: '',
        bar_registration_number: '',
    });
    
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value,
        });
    };
    
    return (
        <CaseDetailsContainer title={t('chat.case_details.case_or_activity.title')} subtitle={t('chat.case_details.case_or_activity.subtitle')}>
            <Container $width="100%">
                <DropDown 
                    label="Type of Judical Case"
                    value={form.type_of_judical_case}
                    onChange={(e) => handleInputChange({ target: { name: 'type_of_judical_case', value: e } })}
                    menuItems={['Civil', 'Criminal', 'Administrative']}
                    backgroundColor="#F6F7F9"
                />

                {
                    form.type_of_judical_case &&
                    <>
                        <Container $height="20px" />

                        <DropDown 
                            label="Civil Case Type"
                            value={form.case_type}
                            onChange={(e) => handleInputChange({ target: { name: 'case_type', value: e } })}
                            menuItems={['Contractual', 'Succession', 'Family Law', 'Damages', 'Property', 'Other']}
                            backgroundColor="#F6F7F9"
                        />
                        
                        {
                            form.case_type &&
                            <>
                                <Container $height="20px" />

                                <Text fontWeight="bold">{t('chat.case_details.case_or_activity.competent_court')}</Text>

                                <Container $height="12px" />
                                
                                <RowWidget $gap="16px">
                                    <Input 
                                        label={t('input.court_name_label')}
                                        placeholder={t('input.court_name_placeholder')}
                                        name="court_name"
                                        value={form.court_name}
                                        onChange={handleInputChange}
                                    />

                                    <DropDown 
                                        label="City"
                                        value="Select city"
                                        onChange={() => {}}
                                        menuItems={[]}
                                        backgroundColor="#F6F7F9"
                                    />
                                </RowWidget>

                                <Container $height="20px" />

                                <RowWidget $gap="16px" $justifyContent="space-between" $alignItems="center">
                                    <Text fontWeight="bold">{t('input.case_number_label')}</Text>

                                    <Container $width="300px">
                                        <Input 
                                            placeholder={t('input.case_number_placeholder')}
                                            name="case_number"
                                            value={form.case_number}
                                            onChange={handleInputChange}
                                        />
                                    </Container>
                                </RowWidget>

                                <Container $height="20px" />
                                
                                <Text fontWeight="bold">{t('input.case_subject_label')}</Text>

                                <Container $height="12px" />

                                <InputTextArea 
                                    placeholder={t('input.case_subject_placeholder')}
                                    name="case_subject"
                                    value={form.case_subject}
                                    onChange={handleInputChange}
                                />

                                <Container $height="20px" />

                                <RowWidget $gap="16px" $justifyContent="space-between" $alignItems="center">
                                    <Text fontWeight="bold">{t('chat.case_details.case_or_activity.hearing_date')}</Text>

                                    <Container $width="300px">
                                        <InputDate 
                                            value={form.hearing_date}
                                            name="hearing_date"
                                            onChange={handleInputChange}
                                        />
                                    </Container>
                                </RowWidget>

                                <Container $height="32px" />

                                <Text fontWeight="bold">{t('chat.case_details.case_or_activity.involved_attorneys')}</Text>

                                <Container $height="12px" />

                                <RowWidget $gap="16px">
                                    <Input 
                                        label={t('input.user_name_label')}
                                        placeholder={t('input.user_name_placeholder')}
                                        name="user_name"
                                        value={form.user_name}
                                        onChange={handleInputChange}
                                    />

                                    <Input 
                                        label={t('input.user_surname_label')}
                                        placeholder={t('input.user_surname_placeholder')}
                                        name="user_surname"
                                        value={form.user_surname}
                                        onChange={handleInputChange}
                                    />
                                </RowWidget>
                                
                                <Container $height="12px" />

                                <RowWidget $gap="16px">
                                    <Input 
                                        label={t('input.bar_association_label')}
                                        placeholder={t('input.bar_association_placeholder')}
                                        name="bar_association"
                                        value={form.bar_association}
                                        onChange={handleInputChange}
                                    />

                                    <Input 
                                        label={t('input.tax_code_label')}
                                        placeholder={t('input.tax_code_placeholder')}
                                        name="tax_code"
                                        value={form.tax_code}
                                        onChange={handleInputChange}
                                    />
                                </RowWidget>

                                <Container $height="12px" />

                                <Input 
                                    label={t('input.bar_registration_number_label')}
                                    placeholder={t('input.bar_registration_number_placeholder')}
                                        name="bar_registration_number"
                                        value={form.bar_registration_number}
                                        onChange={handleInputChange}
                                />
                            </>
                        }
                    </>
                }
            </Container>
        </CaseDetailsContainer>
    );
}

const BrowseButton = styled.label`
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    color: #4158E3;
    font-weight: bold;
    font-size: 16px;
    outline: none;
    border: none;
    background-color: transparent;
    &:hover {
        color: #2E3E9F;
    }
    &:active {
        color: #28368A;
    }
    &:focus {
        color: #28368A;
    }
`;

const CaseDetailsDescription = () => {
    const { t } = useTranslation();

    const [form, setForm] = useState({
        detailed_case_description: '',
    });

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value,
        });
    };

    const [files, setFiles] = useState<File[]>([]);

    const handleFileChange = (e: any) => {
        e.preventDefault();

        const selectedFiles = e.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            const newFiles = Array.from(selectedFiles);
            setFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
        }
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length > 0) {
            const newFiles = Array.from(droppedFiles);
            setFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
        }
    };
    
    return (
        <CaseDetailsContainer title={t('chat.case_details.description.title')} subtitle={t('chat.case_details.description.subtitle')}>
            <Container $width="100%">
                <Text fontWeight="bold">{t('input.detailed_case_description_label')}</Text>

                <Container $height="12px" />

                <InputTextArea 
                    placeholder={t('input.detailed_case_description_placeholder')}
                    name="detailed_case_description"
                    value={form.detailed_case_description}
                    onChange={handleInputChange}
                />

                <Container $height="20px" />

                <Text fontWeight="bold">{t('chat.case_details.description.attached_documents')}</Text>

                <Container $height="12px" />

                <Container
                    $border="1px dashed #E9E9EA"
                    $padding="20px"
                    $borderRadius="12px"
                    onDrop={handleDrop}
                    onDragOver={(event) => event.preventDefault()}
                >
                    <img
                        src={AttachDocumentsIcon}
                        alt="icon"
                        style={{
                            margin: '0 auto',
                            display: 'flex',
                            width: '35px',
                        }}
                    />

                    <Container $height="12px" />

                    <RowWidget $gap="4px" $justifyContent="center" $flexWrap="wrap" style={{ userSelect: 'none' }}>
                        <Text>{t('chat.case_details.description.drag_and_drop')}</Text>
                        <BrowseButton htmlFor="browse">{t('button.browse')}</BrowseButton>
                        <Text>{t('chat.case_details.description.your_files')}</Text>
                    </RowWidget>

                    <input
                        type="file"
                        hidden
                        id="browse"
                        onChange={handleFileChange}
                        accept=".pdf,.docx,.pptx,.txt,.xlsx"
                        multiple
                    />
                </Container>

                <Container $height="12px" />

                {
                    files.map((item, index) =>
                        <RowWidget key={index} $gap="16px" $justifyContent="space-between" $alignItems="center" $margin="0 0 12px 0">
                            <RowWidget $gap="16px" $alignItems="center">
                                <img 
                                    src={DocumentIcon} 
                                    alt="icon" 
                                />

                                <Text fontWeight="bold">{item.name}</Text>
                            </RowWidget>
                            
                            <img 
                                src={DeleteIcon} 
                                alt="icon" 
                                onClick={() => setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))}
                                style={{
                                    cursor: 'pointer',
                                }}
                            />
                        </RowWidget>
                    )
                }
            </Container>
        </CaseDetailsContainer>
    );
}


const CaseDetailsRegulatory = () => {
    const { t } = useTranslation();
    
    const [form, setForm] = useState({
        relevant_laws: '',
        relevant_jurisprudence: '',
    });
    
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value,
        });
    };
    
    return (
        <CaseDetailsContainer title={t('chat.case_details.regulatory.title')} subtitle={t('chat.case_details.regulatory.subtitle')}>
            <Container $width="100%">
                <Text fontWeight="bold">{t('input.relevant_laws_label')}</Text>

                <Container $height="12px" />

                <InputTextArea 
                    placeholder={t('input.relevant_laws_placeholder')}
                    name="relevant_laws"
                    value={form.relevant_laws}
                    onChange={handleInputChange}
                />

                <Container $height="20px" />

                <Text fontWeight="bold">{t('input.relevant_jurisprudence_label')}</Text>

                <Container $height="12px" />

                <InputTextArea 
                    placeholder={t('input.relevant_jurisprudence_placeholder')}
                    name="relevant_jurisprudence"
                    value={form.relevant_jurisprudence}
                    onChange={handleInputChange}
                />
            </Container>
        </CaseDetailsContainer>
    );
}


interface CaseDetailsContainerProps {
    title: string;
    subtitle: string;
    children: JSX.Element;
}

const CaseDetailsContainer: React.FC<CaseDetailsContainerProps> = ({ title, subtitle, children }) => {    
    const [isOpen, setIsOpen] = useState(false);
    
    return (
        <Container onClick={() => setIsOpen(true)} $backgroundColor={isOpen ? "#FFF" : "#E9E9EA"} $padding="30px" $borderRadius="12px" $margin="0 0 32px 0">
            <RowWidget $gap="15%">
                <Container $width="30%">
                    <Text fontSize='18px' fontWeight='bold'>{title}</Text>

                    <Container $height="12px" />

                    <Text color='#908F94'>{subtitle}</Text>
                </Container>

                {
                    isOpen &&
                        <Container $width="calc(100% - 30% - 15%)">
                            {children}
                        </Container>
                }
            </RowWidget>
        </Container>
    );
}